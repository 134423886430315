.blog-post {
  border-bottom: 1px solid #ccc;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.blog-post strong {
  font-weight: bold;
}

.blog-post h2 {
  margin-bottom: 0.5rem;
  font-weight: bolder;
}

/* Apply styles for small screens */
@media (max-width: 767px) {
  .blog-post h2 {
    font-size: 1.2rem;
  }

  .date {
    font-size: 0.8rem;
  }

  .posts {
    padding-top: 0.5rem;
  }
}

/* Apply styles for medium screens and larger */
@media (min-width: 768px) {
  .blog-post {
    max-width: 95%;
  }

  .blog-post h2 {
    font-size: 2rem;
  }

  .date {
    font-size: 1rem;
  }

  .posts {
    padding-top: 1rem;
  }
}

.post-date {
  font-style: italic;
  color: #777;
}

