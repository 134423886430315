.navbar {
  background:-moz-linear-gradient(320deg, rgba(0, 255, 238, 1) 0%, rgba(12, 195, 247, 1) 32%, rgba(0, 17, 93, 1) 100%); 
  background:-webkit-linear-gradient(320deg, rgba(0, 255, 238, 1) 0%, rgba(12, 195, 247, 1) 32%, rgba(0, 17, 93, 1) 100%);
  background:-o-linear-gradient(320deg, rgba(0, 255, 238, 1) 0%, rgba(12, 195, 247, 1) 32%, rgba(0, 17, 93, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00115D', endColorstr='#00FFEE', GradientType=1 );
  background:-ms-linear-gradient(320deg, rgba(0, 255, 238, 1) 0%, rgba(12, 195, 247, 1) 32%, rgba(0, 17, 93, 1) 100%);
  background:linear-gradient(320deg, rgba(0, 255, 238, 1) 0%, rgba(12, 195, 247, 1) 32%, rgba(0, 17, 93, 1) 100%);
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  z-index: 9; 
}

.navbar-logo {
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(6, auto);
  grid-row-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem .77rem;
}

.nav-links:hover {
  background-color: #1888ff;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 1000px) {
  .navbar-logo {
    justify-self: start;
    margin-left: 0px;
    margin-top: -35px;
    cursor: pointer;
  }

  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 40vh;
    position: absolute;
    top: 100px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 0.95;
    height: 550%;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #1888ff;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    padding-top: 10px;
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #ffffff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }

}

@media screen and (max-width: 600px) {
  .nav-menu {
    grid-row-gap: 5px;
  }

  .nav-menu.active {
    height: 510%;
  }
}

@media screen and (max-width: 290px) {
  .nav-menu {
    grid-row-gap: 1px;
  }

  .nav-menu.active {
    height: 510%;
  }
}

@media screen and (max-width: 376px) {
  .nav-menu {
    grid-row-gap: 1px;
  }

  .nav-menu.active {
    height: 460%;
  }

  .nav-item {
    display: flex;
    align-items: center;
    height: 75px;
  }
}