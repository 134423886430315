.home-screen {
        /* ff 3.6+ */
        background:-moz-radial-gradient(circle at 50% 50%, #569acb 0%, #052F56 44%, #000000 100%, #000000 100%);

        /* safari 5.1+,chrome 10+ */
        background:-webkit-radial-gradient(circle at 50% 50%, #569acb 0%, #052F56 44%, #000000 100%, #000000 100%);
    
        /* opera 11.10+ */
        background:-o-radial-gradient(circle at 50% 50%, #569acb 0%, #052F56 44%, #000000 100%, #000000 100%);
    
        /* ie 10+ */
        background:-ms-radial-gradient(circle at 50% 50%, #569acb 0%, #052F56 44%, #000000 100%, #000000 100%);
    
        /* global 92%+ browsers support */
        background:radial-gradient(circle at 50% 50%, #569acb 0%, #052F56 44%, #000000 100%, #000000 100%);
}

.home-outer {
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    text-align: center;
}

.particles {
    position: absolute;
       
    z-index: 0;
}

.home-title {
    position: relative;
    color: #00d5ff;
    margin-top: 250px;
    font-size: 5rem;
    font-weight: bolder;
    z-index: 1;
    text-shadow: 0 0 10px #000000, 0 0 20px #000000, 0 0 30px #007bac;
}

.home-section-1 {
    position: relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
    align-items: center;
    margin-top: -150px;
    padding-top: 100px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    min-height: 600px;
    z-index: 1;
}

.enft-list {
    color: #ffffff;
    text-shadow: 0 0 10px #000000, 0 0 20px #000000, 0 0 30px #007bac;
    max-width: 50%;
    font-size: 2.5rem;
    text-align: left;
    margin-left: 50px;
}

.enft-text {
    padding: 35px;
    width: 50%;
    color:#ffffff;
    font-size: 1rem;
    text-shadow: 0 0 3.5px #000000;
    line-height: 1.25rem;
    word-spacing: .2rem;
}

@media (max-width: 1200px) { 
    .home-title {
        font-size: 4rem;
    }
    
    .home-section-1 {
        width: 90%;
    }
}

@media (max-width: 900px) { 
    .home-title {
        font-size: 3rem;
    }
    
    .home-section-1 {
        width: 100%;
    }
    
    .enft-list {
        font-size: 2rem;
    }
    
    .enft-text {
        width: 60%;
    }
}

@media (max-width: 600px) { 

    .home-outer{
        margin-top: 100px;
        margin-bottom: 0px;
    }

    .home-title {
        font-size: 3rem;
        margin-top: 70px;
    }
    
    .home-section-1 {
        margin-top: -130px;
        flex-flow: column nowrap;
        width: 100%;
    }
    
    .enft-list {
        text-align: center;
        font-size: 1.7rem;
        margin-right: auto;
        margin-left: auto;
    }
    
    .enft-text {
        margin-top: -60px;
        padding: 0;
        width: 80%;
        margin-bottom: 0px;
    }
}

@media (max-width: 400px) { 

    .home-outer{
        margin-bottom: 0px;
       
    }

    .home-title {
        font-size: 3rem;
        margin-top: 40px;
    }
    
    .home-section-1 {
        margin-top: -150px;
        flex-flow: column nowrap;
        width: 100%;
    }
    
    .enft-list {
        text-align: center;
        font-size: 1.6rem;
        margin-right: auto;
        margin-left: auto;
    }
    
    .enft-text {
        margin-top: -60px;
        padding: 0;
        width: 85%;
        margin-bottom: 0px;
    }
}

@media (max-width: 300px) { 

    .home-outer{
        margin-bottom: 0px;
    }

    .home-title {
        font-size: 2rem;
        margin-top: 40px;
    }

    .enft-list {

        font-size: 1.5rem;
    }
    
    .enft-text {
        font-size: .8rem;
        width: 90%;
        margin-top: -70px;
        margin-bottom: 0px;
    }
}