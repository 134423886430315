.outer {
    max-width: 80%;
    padding-left: 50px;
    padding-right: 50PX;
    margin-right: auto;
    margin-left: auto;
}

.link {
    color: #00d5ff;
    text-decoration: none;
    text-transform: none;
  }
  
  .link:hover {
    color: #FFD700;
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
  }
  
  .link:active {
    color: #00d5ff;
    text-decoration: none;
    font-weight: bolder;
  }

.section-1 {
    display: block;
    background-image: url('../images/genesis-1.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #ffffff;
    margin-top: 50px;
    text-align: center;
    border-radius: 40px;
    margin-bottom: 20px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

.title {
    color: #00d5ff;
    padding-top: 35px;
    font-size: 5rem;
    font-weight: bolder;
    text-shadow: 0 0 10px #000000, 0 0 20px #000000, 0 0 30px #007bac;
}

.sub-title {
    color: #00d5ff;
    padding: 35px;
    font-size: 2.5rem;
    font-weight: bold;
}

.cub-gif {
    border-radius: 40px;
}

.cub-text {
    padding: 35px;
    padding-right: 150px;
    padding-left: 150px;
    color:#000000;
    font-size: 1.1rem;
    font-weight: bold;
    text-shadow: 0 0 3.5px #ffffff;
    line-height: 1.25rem;
    word-spacing: .25rem;
}

.section-2 {
    display: flex;
    flex-flow: row wrap;
    gap: 25px;
    background-color: #ffffff;
    border-radius: 40px;
    margin-bottom: 20px;
    width: 90%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    justify-content: space-evenly;
    align-items: stretch;
    margin-top: 50px;
    text-align: center;
    padding-top: 75px;
    padding-bottom: 75px;
    padding-left: 50px;
    padding-right: 50px;
}

.past {
    text-align: left;
    padding: 25px;
    font-size: 1.7rem;
    font-weight: bold;
    outline: 3px solid;
    outline-color: #00d5ff;
    max-width: 350px;
    line-height: 1.2rem;
}

.present {
    text-align: left;
    padding: 25px;
    font-size: 1.7rem;
    font-weight: bold;
    outline: 3px solid;
    outline-color: #00d5ff;
    max-width: 350px;
    line-height: 1.2rem;
}

.future {
    text-align: left;
    padding: 25px;
    font-size: 1.7rem;
    font-weight: bold;
    outline: 3px solid;
    outline-color: #00d5ff;
    max-width: 350px;
    line-height: 1.2rem;
}

.list {
    padding-top: 10px;
    list-style-type: disc;
    font-size: 1.1rem;
    font-weight: normal;
    list-style-position: inside;
}

.list-item {
    padding-top: 5px;
}

.section-3 {
    display: flex;
    flex-flow: row nowrap;
    text-align: center;
    width: 90%;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    gap: 20px;
    justify-content: space-evenly;
    align-items: center;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: 100%;
    height: auto;
    opacity: 0;
    transition: .5s ease;
    background-color: #008CBA;
    border-radius: 20%;
    margin-left: auto;
    margin-right: auto;
  }

.donation-section {
    position: relative;
    border-radius: 20%;

}

.donation-img {
    display: block;
    max-width: 100%;
    height: auto;
    border-radius: 20%;
    margin-left: auto;
    margin-right: auto;
}

.donation-section:hover .overlay {
    opacity: 1;
  }

.donation-title {
    font-size: 2rem;
    font-weight: bolder;
    color: #05b6b6;
    word-spacing: .25rem;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.donation-body {
    font-size: 1.1rem;
    font-weight: normal;
    word-spacing: .25rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.donation-button {
    padding: 15px;
    background-color: #FFE502;
    border-radius: 35px;
    color: #168661;
    text-decoration: none;
    position: absolute;
    top: 67%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.donation-button:hover {
    color: #ff0000;
    font-weight: bold;
}

.multi-section {
    position: relative;
    border-radius: 20%;
}

.multi-img {
    display: block;
    max-width: 100%;
    height: auto;
    border-radius: 20%;
    margin-left: auto;
    margin-right: auto;
}

.multi-section:hover .overlay {
    opacity: 1;
  }


.multi-title {
    font-size: 2rem;
    font-weight: bolder;
    color: #05b6b6;
    word-spacing: .25rem;
    position: absolute;
    top: 35%;
    left: 50%;
    margin-right: -50px;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.multi-body {
    font-size: 1.1rem;
    font-weight: normal;
    word-spacing: .25rem;
    position: absolute;
    top: 60%;
    left: 50%;
    margin-right: -50px;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
}

.section-4 {
    display: flex;
    flex-flow: row;
    gap: 25px;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    border-radius: 40px;
    margin-bottom: 50px;
    width: 90%;
    height: auto;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    padding: 50px;
    background:-moz-linear-gradient(90deg, #1cb7cf 10%, #ffffff 95%); 
    background:-webkit-linear-gradient(90deg, #1cb7cf 10%, #ffffff 95%);
    background:-o-linear-gradient(90deg, #1cb7cf 10%, #ffffff 95%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#1cb7cf', GradientType=0 );
    background:-ms-linear-gradient(90deg, #1cb7cf 10%, #ffffff 95%);
    background:linear-gradient(90deg, #1cb7cf 10%, #ffffff 95%);
}

.crypto-img {
    display: block;
    width: auto;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 35px;
    -webkit-filter: drop-shadow(5px 5px 5px #4f4f4f);
        filter: drop-shadow(5px 5px 5px #4f4f4f);
}

.chain-section {
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 35px;
    padding-bottom: 35px;
    background-color: #FFD700;
    border-radius: 40px;
    max-width: 270px;
    box-shadow: -4px 4px 7px 2px #000000;
}

.chain-name {
    font-size: 2.7rem;
    word-spacing: .25rem;
    color: #1cb7cf;
    font-weight: bolder;
    font-family: Garamond, Helvetica, sans-serif;
    text-shadow: 0 0 10px #000000, 0 0 20px #007bac;
    padding-bottom: 15px;
    -webkit-text-stroke: .5px black;

}

.chain-body {
    color:#000000;
    font-size: 1.1rem;
    line-height: 1.15rem;
    word-spacing: .20rem;
    padding-right: 10px;
    padding-left: 10px;
}

@media (max-width: 1800px) { 
    .past {
        max-width: 300px;
    }
    
    .present {
        max-width: 300px;
    }
    
    .future {
        max-width: 300px;
    }

    .donation-title {
        margin-right: -50px;
    }
    
    .donation-body {
        margin-right: -50px;
    }
    
    .donation-button {
        top: 67%;
        left: 50%;
    }

    .multi-title {
        margin-right: -100px;
        padding-bottom: 30px;
    }

    .chain-section {
        max-width: 300px;
    }
    
}

@media (max-width: 1600px) { 
    .cub-gif {
        max-width: 400px;
        height: auto;
    }

    .section-2 {
        flex-flow: column nowrap;
        align-items: center;
    }

    .past {
        max-width: 80%;
    }
    .present {
        max-width: 80%;
    }
    .future {
        max-width: 80%;
    }

    .donation-title {
        font-size: 1.7rem;
        margin-right: -100px;
        padding-bottom: 35px;
    }
    
    .donation-body {
        margin-right: -100px;
    }
    
    .donation-button {
        top: 75%;
    }

    .multi-title {
        font-size: 1.7rem;
        margin-right: -100px;
        padding-bottom: 35px;
    }
    
    .multi-body {
        font-size: 1rem;
        margin-right: -120px;
    }

    .section-4 {
        flex-flow: row wrap;
    }
}


@media (max-width: 1200px) { 

    .title {
        font-size: 4rem;
    }
    
    .sub-title {
        font-size: 2rem;
    }
    
    .cub-gif {
        max-width: 350px;
        height: auto;
    }
    
    .cub-text {
        font-size: 1rem;
        padding-right: 125px;
        padding-left: 125px;
    }

    .section-3 {
        flex-flow: column nowrap;
    }

    .donation-title {
        font-size: 2rem;
    }
    
    .donation-body {
        font-size: 1.2rem;
    }
    
    .donation-button {
        top: 70%;
    }

    .multi-title {
        font-size: 2rem;
        padding-bottom: 0px;
    }
    
    .multi-body {
        font-size: 1.2rem;
    }

    .chain-section {
        padding: 50px;
        max-width: 80%;
    }
}

@media (max-width: 900px) {

    .outer {
        padding-left: 25px;
        padding-right: 25px;
    }

    .title {
        font-size: 3rem;
    }
    
    .sub-title {
        font-size: 1.5rem;
    }
    
    .cub-gif {
        max-width: 300px;
        height: auto;
    }
    
    .cub-text {
        font-size: .9rem;
        padding-right: 75px;
        padding-left: 75px;
    }

    .section-2 {
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 25px;
        padding-right: 25px;
    }

    .past {
        font-size: 1.4rem;
    }
    
    .present {
        font-size: 1.4rem;
    }
    
    .future {
        font-size: 1.4rem;
    }
    
    .list {
        font-size: 1rem;
    }

    .donation-title {
        font-size: 1.7rem;
    }
    
    .donation-body {
        font-size: 1rem;
    }
    
    .donation-button {
        top: 70%;
    }

    .multi-title {
        font-size: 1.7rem;
        margin-right: -150px;
        padding-bottom: 0px;
    }
    
    .multi-body {
        font-size: 1rem;
    }

    .section-4 {
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 0px;
        padding-right: 0px;
    }
    
    .chain-name {
        font-size: 1.5rem;
    }
    
    .chain-body {
        font-size: 1rem;
        padding-right: 0px;
        padding-left: 0px;
    }
}

@media (max-width: 650px) {

    .section-1 {
        width: 100%;
    }

    .title {
        font-size: 2.5rem;
    }
    
    .sub-title {
        font-size: 1.2rem;
    }
    
    .cub-gif {
        max-width: 200px;
        height: auto;
    }
    
    .cub-text {
        font-size: .8rem;
        padding-right: 75px;
        padding-left: 75px;
    }

    .section-2 {
        width: 100%;
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .past {
        font-size: 1.2rem;
    }
    
    .present {
        font-size: 1.2rem;
    }
    
    .future {
        font-size: 1.2rem;
    }
    
    .list {
        font-size: 0.8rem;
    }

    .section-3 {
        width: 100%;
    }

    .donation-title {
        font-size: 1.4rem;
    }
    
    .donation-body {
        font-size: 0.8rem;
    }
    
    .donation-button {
        top: 75%;
        font-size: 0.8rem;
    }

    .multi-title {
        font-size: 1.4rem;
        top: 25%;
    }
    
    .multi-body {
        font-size: 0.9rem;
        top: 55%;
    }

    .section-4 {
        width: 100%;
    }
    
    .chain-name {
        font-size: 1.3rem;
    }
    
    .chain-body {
        font-size: 0.8rem;
    }
}

@media (max-width: 450px) {
    
    .outer {
        width: 100%;
        padding-left: 5px;
        padding-right: 5px;
    }

    .title {
        font-size: 2.1rem;
        width: 80%;
        margin-left: auto;
        margin-right: auto;
    }
    
    .sub-title {
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 1rem;
    }
    
    .cub-gif {
        max-width: 150px;
        height: auto;
    }
    
    .cub-text {
        padding-top: 15px;
        font-size: .8rem;
        padding-right: 25px;
        padding-left: 25px;
    }

    .section-2 {
        margin-top: 15px;
        gap: 20px;
    }

    .past {
        font-size: 1.15rem;
        line-height: 1rem;
    }
    
    .present {
        font-size: 1.15rem;
        line-height: 1rem;
    }
    
    .future {
        font-size: 1.15rem;
        line-height: 1rem;
    }

    .section-3 {
        margin-top: 15px;
        gap: 15px;
    }

    .donation-title {
        font-size: 1.3rem;
    }

    .donation-body {
        margin-right: -110px;
    }

    .multi-title {
        font-size: 1.3rem;
        top: 25%;
    }
    
    .multi-body {
        font-size: 0.8rem;
        top: 55%;
    }

    .section-4 {
        margin-top: 15px;
        margin-bottom: 150px;
    }

    .chain-section {
        padding: 20px;
    }
    
    .chain-name {
        font-size: 1.2rem;
    }
    
    .chain-body {
        font-size: 0.8rem;
        line-height: 1rem;
        word-spacing: .10rem;
    }
}

@media (max-width: 376px) {

    .outer {
        max-width: 100%;
        padding-left: 5px;
        padding-right: 5PX;
        margin-right: auto;
        margin-left: auto;
    }

    .donation-button {
        top: 80%;
    }

    .multi-title {
        top: 20%;
    }
    
    .multi-body {
        font-size: 0.8rem;
        top: 60%;
    }

}

@media (max-width: 330px) {

    .title {
        font-size: 1.7rem;
    }
    
    .cub-gif {
        max-width: 125px;
        height: auto;
    }
    
    .cub-text {
        font-size: .7rem;
        padding-right: 15px;
        padding-left: 15px;
        line-height: 0.9rem;
    }

    .past {
        font-size: 1.1rem;
        line-height: 1rem;
        padding: 15px;
    }
    
    .present {
        font-size: 1.1rem;
        line-height: 1rem;
        padding: 15px;
    }
    
    .future {
        font-size: 1.1rem;
        line-height: 1rem;
        padding: 15px;
    }

    .list {
        font-size: 0.7rem;
    }

    .donation-title {
        font-size: 1rem;
        top: 40%;
    }

    .donation-body {
        font-size: 0.65rem;
        margin-right: -90px;
        word-spacing: 0rem;
    }

    .donation-button {
        font-size: 0.65rem;
    }

    .multi-title {
        font-size: 1rem;
        top: 20%;
    }
    
    .multi-body {
        font-size: 0.65rem;
        top: 60%;
        margin-right: -90px;
        word-spacing: 0rem;
    }

    .section-4 {
        padding-top: 25px;
        padding-bottom: 25px;
    }
    
    .chain-name {
        font-size: 1rem;
    }
    
    .chain-body {
        font-size: 0.7rem;
    }

}