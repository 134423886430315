.App {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  border-radius: 25;
  background-color: aliceblue;
  opacity: 67%;
  border-radius: 20px;
  margin-right: auto;
  margin-left: auto;
  width: 90%;
  margin-top: 25px;
  margin-bottom: 25px;
}

.Posts {
  max-width: 800px;
  margin: 0 auto;
}

/* Apply styles for small screens */
@media (max-width: 767px) {
  .Posts h1 {
    font-size: 1.5rem;
    padding-bottom: 0.5rem;
    font-style: italic;
    text-decoration: underline;
  }
}

/* Apply styles for medium screens and larger */
@media (min-width: 768px) {
  .Posts h1 {
    font-size: 2rem;
    padding-bottom: 1rem;
    text-decoration: underline;
  }
}

.Posts h1 {
  color: #000000;
  margin-top: 25px;
  opacity: 100%;
  font-weight: bolder;
  text-align: center;
}