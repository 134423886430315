.Form {
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-align: center;
}
input {
  width: 320px;
  height: 35px;
  font-size: 1.1em;
  font-weight: 500;
  text-align: center;
}

#traits{
  width: 320px;
  height: 35px;
  padding: 5px;
  font-size: 1em;
  font-weight: 500;
}

.layers {
  margin-left: -13rem;
  width: 420px;
  height: 420px;
  position: absolute;
}

.dice {
  width: 35px;
  height: 35px;
}

label {
  width: 320px;
  height: 35px;
  padding: 0px;
  margin-top: 5px;
  margin-bottom: 7px;
  font-size: 1em;
  font-weight: 500;
  text-align: center;
}

option {
  width: 320px;
  height: 35px;
  padding: 5px;
  font-size: 1em;
  font-weight: 500;
}

button {
  border: none;
  padding: 1rem;
  width: 21.2rem;
  font-size: 1.2em;
  border-radius: 0.2rem;
  cursor: pointer;
}
button:hover {
  background-color: #c5c5c5;
}
.message {
  font-size: 1.2em;
  text-align: center;
  color: #36a936;
}

@media (max-width: 512px) {
  .layers {
    margin-left: -8rem;
    width: 256px;
    height: 256px;
    position: absolute;
  }

  .dice {
    width: 25px;
    height: 25px;
  }
}

@media (max-width: 400px) {
  .Form {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }

  input {
    width: 100%;
    height: auto;
  }

  #traits{
    width: 100%;
    height: auto;
    }

  label {
    width: 100%;
    height: auto;
  }

  option {
    width: 100%;
    height: auto;
  }

  button {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 360px) {
  .layers {
    margin-left: -6rem;
    width: 200px;
    height: 200px;
    position: absolute;
  }
  .dice {
    width: 20px;
    height: 20px;
    margin-left: -2px;
    margin-top: -2px;
  }
}

@media (max-width: 280px) {
  .layers {
    margin-left: -4.7rem;
    width: 150px;
    height: 150px;
    position: absolute;
  }
  .dice {
    width: 15px;
    height: 15px;
    margin-left: -2px;
    margin-top: -2px;
  }
}
