.mutant {
    background-image: url('../images/mutants-1.jpg');
    background-size: 420px;
    width: 100%;
    min-height: 79.2vh;
    background-repeat: repeat;
    margin-top: 90px;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
    display: flex;
    flex-direction: column;
}

.mutant-title {
    z-index: 1;
    font-size: 8rem;
    margin-left: auto;
    margin-right: auto;
    font-weight: bolder;
    text-align: center;
    color: coral;
    text-shadow: 0 0 10px #000000, 0 0 20px #000000, 0 0 30px #007bac;
    opacity: .9;
}

.image {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.item {
    background-color: #009eac;
    width: 420px;
    height: 420px;
    padding: 40px;
    margin: 20px;
    border-radius: 40px;
}

@media (max-width: 900px) { 

    .mutant {
        background-size: 300px;
    }

    .mutant-title {

        font-size: 6rem;
    }
}

@media (max-width: 600px) { 

    .mutant {
        background-size: 200px;
    }

    .mutant-title {

        font-size: 4rem;
    }
}

@media (max-width: 300px) { 

    .mutant {
        background-size: 150px;
    }
    .mutant-title {

        font-size: 2rem;
    }
}