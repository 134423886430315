.u-section-1 .u-sheet-1 {
    min-height: 854px;
    max-width: 1200px;
    align-items: center;
    margin-bottom: 150px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .u-section-1 .u-text-1 {
    margin: 14px auto 0 0;
    margin-top: 150px;
    text-align: left;
    color: #9affff;
    font-weight: bold;
    font-size: 5rem;
    text-shadow: 0 0 10px #000000, 0 0 20px #000000, 0 0 30px #007bac;
  }
  
  .u-section-1 .u-list-1 {
    width: 588px;
    margin: -7px 0 0 auto;
    padding-top: 50px;
    align-items: center;
    
  }
  
  .u-section-1 .u-repeater-1 {
    grid-template-columns: repeat(2, calc(50% - 5px));
    min-height: 653px;
    grid-gap: 10px;
    text-align: center;
    background-color: white;
    opacity: 0.8;
  }
  
  .u-section-1 .u-container-layout-1 {
    padding: 20px 7px;
  }
  
  .u-section-1 .u-text-2 {
    font-size: 3rem;
    font-weight: 700;
    color: #25acff;
    margin: 0 auto;
  }
  
  .u-section-1 .u-text-3 {
    letter-spacing: 2px;
    font-weight: 600;
    text-transform: uppercase;
    color: #757575;
    margin: 19px 6px 0;
  }
  
  .u-section-1 .u-text-4 {
    margin: 30px 23px 0;
  }
  
  .u-section-1 .u-container-layout-2 {
    padding: 20px 7px;
  }
  
  .u-section-1 .u-text-5 {
    font-size: 3rem;
    color: #25acff;
    font-weight: 700;
    margin: 0 auto;
  }
  
  .u-section-1 .u-text-6 {
    letter-spacing: 2px;
    font-weight: 600;
    text-transform: uppercase;
    color: #757575;
    margin: 19px 6px 0;
  }
  
  .u-section-1 .u-text-7 {
    margin: 30px 23px 0;
  }
  
  .u-section-1 .u-container-layout-3 {
    padding: 20px 7px;
  }
  
  .u-section-1 .u-text-8 {
    font-size: 3rem;
    font-weight: 700;
    color: #25acff;
    margin: 0 auto;
  }
  
  .u-section-1 .u-text-9 {
    letter-spacing: 2px;
    font-weight: 600;
    text-transform: uppercase;
    color: #757575;
    margin: 19px 6px 0;
  }
  
  .u-section-1 .u-text-10 {
    margin: 30px 23px 0;
  }
  
  .u-section-1 .u-container-layout-4 {
    padding: 20px 7px;
  }
  
  .u-section-1 .u-text-11 {
    font-size: 3rem;
    font-weight: 700;
    color: #25acff;
    margin: 0 auto;
  }
  
  .u-section-1 .u-text-12 {
    letter-spacing: 2px;
    font-weight: 600;
    text-transform: uppercase;
    color: #757575;
    margin: 19px 6px 0;
  }
  
  .u-section-1 .u-text-13 {
    margin: 30px 23px 0;
  }
  
  .u-section-1 .u-group-1 {
    width: 533px;
    min-height: 566px;
    height: auto;
    margin: -655px auto 60px 0;
    background-color: coral;
    opacity: 0.9;
    text-align: center;
  }
  
  .u-section-1 .u-container-layout-5 {
    padding: 32px 40px;
  }
  
  .u-section-1 .u-text-14 {
    font-weight: 700;
    font-size: 2.25rem;
    color: #8ff3f3;
    line-height: 1.2;
    margin: 37px 0 0;
  }
  
  .u-section-1 .u-text-15 {
    margin: 43px 0 0;
    color: white;
    padding-bottom: 10px;
  }
  
  .u-icon-1 {
    width: 200px;
    height: 200px;
    margin: 30px auto 0;
    padding: 0;
  }
  
  @media (max-width: 1199px) {
    .u-section-1 .u-sheet-1 {
      min-height: 834px;
      padding-bottom: 150px;
    }
  
    .u-section-1 .u-text-1 {
      width: auto;
      margin-top: 175px;
    }
  
    .u-section-1 .u-list-1 {
      width: 500px;
      margin-top: 10px;
    }
  
    .u-section-1 .u-repeater-1 {
      grid-template-columns: calc(50% - 5px) calc(50% - 5px);
      min-height: 800px;
      margin-left: 20px;
      grid-gap: 10px 10px;
    }
  
    .u-section-1 .u-group-1 {
      width: 438px;
      min-height: 550px;
      margin-top: -700px;
      margin-bottom: 53px;
      height: auto;
    }
  
    .u-section-1 .u-text-14 {
      margin-top: 0;
    }
  }

  .u-icon-1 {
    width: 180px;
    height: 180px;
    margin: 30px auto 0;
    padding: 0;
  }
  
  @media (max-width: 991px) {
    .u-section-1 .u-sheet-1 {
      min-height: 1106px;
      padding-bottom: 250px;
      width: 400px;
    }
  
    .u-section-1 .u-text-1 {
      margin-top: 150px;
      font-size: 3rem;
      text-align: center;
    }
  
    .u-section-1 .u-list-1 {
      margin-top: 400px;
      width: 400px;
      margin-right: auto;
      margin-left: auto;

    }
  
    .u-section-1 .u-repeater-1 {
      margin-top: 100px;
      min-height: 685px;
      margin-right: auto;
      margin-left: auto;
    }
  
    .u-section-1 .u-text-4 {
      margin-left: 0;
      margin-right: 0;
    }
  
    .u-section-1 .u-text-7 {
      margin-left: 0;
      margin-right: 0;
    }
  
    .u-section-1 .u-text-10 {
      margin-left: 0;
      margin-right: 0;
    }
  
    .u-section-1 .u-text-13 {
      margin-left: 0;
      margin-right: 0;
    }
  
    .u-section-1 .u-group-1 {
      margin-top: -1300px;
      margin-bottom: 550px;
      min-height: 432px;
      margin-right: auto;
      margin-left: auto;
      width: 400px;

    }
  
    .u-section-1 .u-container-layout-5 {
      padding: 15px 30px 0;
    }

    .u-section-1 .u-icon-1 {
        width: 140px;
        height: 140px;
      }
  }
  
  @media (max-width: 575px) {
    .u-section-1 .u-sheet-1 {
      min-height: 1300px;
      margin-top: 150px;
      width: 90%;
      display: flex; 
      flex-direction: column;
    }
  
    .u-section-1 .u-text-1 {
      font-size: 2.7rem;
      margin-top: 0;
      padding-bottom: 15px;
    }

    .u-section-1 .u-list-1  {
  
        /* Setup Flexbox */
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
      
        /* Reverse Column Order */
        -webkit-flex-flow: column-reverse;
        flex-flow: column-reverse;
      
      }
  
    .u-section-1 .u-list-1 {
      width: auto;
      margin-right: initial;
      margin-left: initial;
      
    }
  
    .u-section-1 .u-repeater-1 {
      margin-bottom: 25px;
      min-height: 850px;
      padding-right: 25px;
      margin-top: auto;
    }
  
    .u-section-1 .u-group-1 {
      min-height: 396px;
      width: auto;
    }
  
    .u-section-1 .u-container-layout-5 {
      padding-left: 20px;
      padding-right: 20px;
    }
  
    .u-section-1 .u-text-14 {
      font-size: 1.7rem;
      margin-top: 15px;
    }

    .u-section-1 .u-text-15 {
        font-size: 1rem;
        margin-top: 10px;
      }
  
    .u-section-1 .u-icon-1 {
      margin-top: -5px;
      width: 120px;
      height: 120px;;
    }
  }

  @media (max-width: 448px) {
    
      .u-section-1 .u-text-1 {
        font-size: 2.5rem;
      }
  
    
      .u-section-1 .u-list-1 {
        margin-top: auto;
        margin-bottom: 25px;
      }
    
      .u-section-1 .u-repeater-1 {
        margin-bottom: 100px;
        min-height: 650px;
        padding-right: 30px;
        padding-left: 30px;
        padding-top: 15px;
        padding-bottom: 15px;
      }
    
      .u-section-1 .u-group-1 {
        width: auto;
        min-height: 350px;
      }

      .u-section-1 .u-text-2 {
        font-size: 2rem;
        margin-bottom: -10px;
      }

      .u-section-1 .u-text-4 {
        margin-top: 15px;
      }

      .u-section-1 .u-text-5 {
        margin-top: -10px;
        font-size: 2rem;
        margin-bottom: -10px;
      }

      .u-section-1 .u-text-7 {
        margin-top: 15px;
      }
      
      .u-section-1 .u-text-8 {
        margin-top: -10px;
        font-size: 2rem;
        margin-bottom: -10px;
      }

      .u-section-1 .u-text-10 {
        margin-top: 15px;
      }
      
      .u-section-1 .u-text-11 {
        font-size: 2rem;
        margin-top: -10px;
        margin-bottom: -10px;
      }

      .u-section-1 .u-text-13 {
        margin-top: 15px;
      }

      .u-section-1 .u-text-14 {
        font-size: 1.4rem;
        margin-top: 15px;
      }
  
      .u-section-1 .u-text-15 {
          font-size: 1rem;
          margin-top: 10px;
        }
    
      .u-section-1 .u-icon-1 {
        margin-top: -5px;
        width: 120px;
        height: 120px;;
      }

  }

  @media (max-width: 400px) {

    .u-section-1 .u-list-1 {
        margin-bottom: 15px;
      }

    .u-section-1 .u-repeater-1 {
        margin-bottom: 80px;
      }

  }

  @media (max-width: 376px) {
    
    .u-section-1 .u-text-1 {
      font-size: 2.5rem;
    }

  
    .u-section-1 .u-list-1 {
      margin-top: auto;
      margin-bottom: 75px;
    }
  
    .u-section-1 .u-repeater-1 {
      margin-bottom: 35px;
      min-height: 650px;
      padding-right: 30px;
      padding-left: 30px;
      padding-top: 15px;
      padding-left: 15px;
    }
  
    .u-section-1 .u-group-1 {
      width: auto;
      min-height: 350px;
      padding-bottom: 15px;
    }

  
    .u-section-1 .u-icon-1 {
      margin-top: -5px;
      width: 100px;
      height: 100px;;
    }

}

@media (max-width: 335px) {

      .u-section-1 .u-list-1 {
        margin-top: auto;
        display: flex;
        gap: 2em;
  
      }

      .u-section-1 .u-text-14 {
        font-size: 18px;
      }

      .u-section-1 .u-text-15 {
        font-size: 12px;
      }

      .u-section-1 .u-text-2 {
        font-size: 28px;
      }

      .u-section-1 .u-text-3 {
        font-size: 14px;
      }

      .u-section-1 .u-text-4 {
        font-size: 14px;
      }

      .u-section-1 .u-text-5 {
        font-size: 28px;
      }

      .u-section-1 .u-text-6 {
        font-size: 14px;
      }

      .u-section-1 .u-text-7 {
        font-size: 14px;
      }

      .u-section-1 .u-text-8 {
        font-size: 28px;
      }

      .u-section-1 .u-text-9 {
        font-size: 14px;
      }

      .u-section-1 .u-text-10 {
        font-size: 14px;
      }

      .u-section-1 .u-text-11 {
        font-size: 28px;
      }

      .u-section-1 .u-text-12 {
        font-size: 14px;
      }

      .u-section-1 .u-text-13 {
        font-size: 14px;
      }
  
      .u-section-1 .u-icon-1 {
          width: 80px;
          height: 80px;
        }
}