* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }

  ul.navbar-nav {
    display: inline-block;
    margin: 3px;
    width: 100%;
    align-items: center;
    text-align: center;
         
}
